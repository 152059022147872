   * {
    box-sizing: border-box;
  }
  
  body {
    background-color: #f5f7fa;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .confirm-phrase-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
    background-color: #f5f7fa;
  }
  
  .top-bar {
    background-color: #f5f7fa;
    color: Black;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    font-size: 18px;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .back-icon {
    margin-right: 10px;
    cursor: pointer;
    font-size: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin: 20px 0;
    color: #333;
    text-align: center;
  }
  
  p {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .word-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 600px;
    max-width: 600px;
    margin-top: 20px;
  }
  
  .input-group {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 6px;
    font-size: 16px;
    text-align: left;
    color: #333;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
  
  .input-group label {
    font-size: 14px;
    color: #999;
  }
  

  
  .word-button {
    padding: 10px 15px;
    background-color: #e0e0e0;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
    color: #333;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .word-button:hover {
    background-color: #d0d0d0;
  }
  
  .word-button.selected {
    background-color: #5c3fd1;
    color: white;
  }
  
  .new-wallet-submit-button {
    background-color: #5c3fd1;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-top: 30px;
    max-width: 300px;
    width: 100%;
  }
  

  @media (max-width: 768px) {
    .word-grid {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }
  
    .word-button {
      font-size: 12px;
      padding: 8px 10px;
    }
  
    button {
      font-size: 14px;
      padding: 12px 20px;
    }
  }
  