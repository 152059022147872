/* General container styling */


.home-page-container {
    margin: auto;
    margin-bottom: 100px;
    font-family: Arial, sans-serif;
    background-color: #F4F5FB;
    border: 1px solid transparent;
    border-radius: 10px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #3C3C3C;
}

/* Header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    align-items: center;
    padding: 10px 20px 0px 20px;
}

.header h1 {
    font-size: 1.2rem;
    color: #5C3FD1;
}

.header .notification-icon {
    font-size: 1.2rem;
    color: #5C3FD1;
}

/* Wallet Card */
.wallet-card {
    background: linear-gradient(145deg, #E4DFFF, #C1B6FD);
    border-radius: 20px;
    padding: 20px;
    color: white;
    text-align: center;
    width: 60%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.wallet-card .wallet-title {
    font-size: 1rem;
}

.wallet-card .balance {
    font-size: 2rem;
    font-weight: bold;
}

.wallet-card .balance-change {
    font-size: 0.9rem;
    color: #00FFAA;
    margin-top: 10px;
}

/* Action buttons */
.action-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.logout-button{
    border: none;
    background: none;
    color: #5C3FD1;
    transform: scale(1.2,1.7);
}

.action-buttons .button {
    background-color: #5C3FD1;
    border: none;
    color: white;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    margin: 0 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

/* Transactions */
.transactions {
    margin-top: 20px;
    width: 100%;
    padding: 0 20px;
}

.transactions .title {
    font-size: 1rem;
    font-weight: bold;
    color: #5C3FD1;
    margin-bottom: 10px;
}

.transaction-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #E6E8F0;
    border-radius: 10px;
    margin-bottom: 10px;
}

.transaction-item:nth-child(even) {
    background-color: #D9DBEA;
}

.transaction-item .transaction-details {
    display: flex;
    align-items: center;
}

.transaction-item .transaction-icon {
    background-color: white;
    color: #5C3FD1;
    border: solid 2px #5C3FD1;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.transaction-label{
    font-weight: bold;
}

.tx-icon{
    font-size: 14px;
}

.transaction-item .transaction-info {
    font-size: 0.9rem;
    color: #3C3C3C;
}

.transaction-item .transaction-amount {
    font-size: 0.9rem;
    color: #5C3FD1;
}



#send-button,
#copy-button,
#cancel-logout-button {
    background-color: #5C3FD1;
    border-color: #5C3FD1;
}

.transaction-modal {
    background-color: #F4F5FB;
}

.no-border {
    border-bottom: none !important;
    border-top: none !important;
}

.is-invalid {
    background-image: none !important;
}

.is-invalid {
    border-color: #dc3545 !important;
}
