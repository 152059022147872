.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    /* background-color: #f5f5f5;  */
  }
  
  .login-button {
    background-color: #007bff; 
    color: white;
    padding: 15px 30px; 
    font-size: 20px; 
    border: none;
    border-radius: 8px; 
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .login-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  .login-button:active {
    background-color: #004085; 
    transform: scale(1);
  }
  