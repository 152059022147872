.bottom-nav {
    position: sticky;
    width: 100%;
    background-color: #e9e9ee;
    box-shadow: 0px -10px 10px -2px #dbdbdd ;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    z-index: 100;
    padding: 15px 100px;
}

.nav-icons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
}

.bottom-nav .nav-icon {
    font-size: 1.5rem;
    color: #5C3FD1;
}

.bottom-nav .nav-icon.active {
    color: #5C3FD1;
}