
.page-container {
  margin: auto;
  position: relative;
  font-family: Arial, sans-serif;
  margin-top: 15px;
  box-shadow: 0px 0px 10px #e5e6eb;
  border: 1px solid #e5e6eb;
  border-radius: 10px;
  background-color: #F4F5FB;
  width: 70%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3C3C3C;
}