* {
    box-sizing: border-box;
  }
  
  body {
    background-color: #f5f7fa;
    font-family: Arial, sans-serif;
  }
  
  .profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    color: #5C3FD1;
  }
  
  .profile-icon {
    font-size: 24px;
    color: #5C3FD1;
  }
  
  .profile-info {
    text-align: center;
    margin-top: 20px;
  }
  
  .profile-picture {
    position: relative;
    display: inline-block;
  }
  
  .profile-picture img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  .edit-icon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background: white;
    padding: 4px;
    border-radius: 50%;
    color: #5C3FD1;
    cursor: pointer;
  }
  
  .profile-info h3 {
    font-size: 20px;
    margin: 10px 0;
  }
  
  .wallet-section h4 {
    margin-top: 30px;
    font-size: 18px;
    color: #5C3FD1;
  }
  
  .wallet-list {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  
  .wallet-button {
    padding: 8px 15px;
    border-radius: 20px;
    border: 1px solid #5C3FD1;
    background: white;
    color: #5C3FD1;
    font-size: 14px;
    cursor: pointer;
  }
  
  .wallet-button.active {
    background-color: #5C3FD1;
    color: white;
  }
  
  .wallet-actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .add-wallet-icon {
    font-size: 20px;
    color: #5C3FD1;
    cursor: pointer;
  }
  
  .wallet-details {
    margin-top: 20px;
    font-size: 14px;
  }
  
  .wallet-details p {
    display: flex;
    align-items: center;
  }
  
  .copy-icon {
    margin-left: 5px;
    color: #5C3FD1;
    cursor: pointer;
  }
  
  .default-button {
    padding: 8px 15px;
    border-radius: 8px;
    width: 50%;
    border: 1px solid #5C3FD1;
    background: #5C3FD1;
    color: white;
    font-size: 14px;
    cursor: pointer;
  }
  .default-button:hover{
    background-color: #6c63ff;
  }
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .export-button {
    border: 1px solid #5C3FD1;
    color: #5C3FD1;
    background: none;
    padding: 10px;
    width: 48%;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .delete-button {
    color: red;
    background: none;
    padding: 10px;
    width: 48%;
    font-size: 14px;
    cursor: pointer;
  }
  
  .profile-footer {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
  }
  
  .footer-icon, .footer-scan {
    font-size: 16px;
    color: #5C3FD1;
    background: none;
    border: none;
    cursor: pointer;
  }

  /* profile-page.css */

/* General Page Styling */
.profile-page {
  max-width: 800px;
  margin-top: none;
  background-color: #f8f9fa;
  border-radius: 10px;
}

/* Profile Picture Styling */
.profile-page .profile-picture {
  position: relative;
}

.profile-page .profile-picture img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #6c63ff;
}

.profile-page .profile-picture .btn {
  position: absolute;
  bottom: 0;
  right: 5px;
  color: #6c63ff;
  padding: 0.25rem;
  background: #fff;
  border-radius: 50%;
}

/* Profile Header */
.profile-page h4 {
  color: #6c63ff;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.profile-page p {
  color: #6c757d;
  font-size: 0.9rem;
}

/* Tabs Styling */
.profile-page .nav-tabs .nav-link {
  color: #6c63ff;
  font-weight: 500;
}

.profile-page .nav-tabs .nav-link.active {
  background-color: #6c63ff;
  color: #fff !important;
  border-radius: 5px;
}

/* Wallet Information Section */
.wallet-info {
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 1.5rem;
}

.wallet-info h6 {
  font-weight: bold;
  color: #6c63ff;
}

.wallet-info .form-control {
  background-color: #f1f3f5;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #495057;
  cursor: not-allowed;
}

.wallet-info .btn-link {
  color: #6c63ff;
  font-size: 0.85rem;
  text-decoration: none;
}

.wallet-info .btn-link:hover {
  color: #5a54c4;
  text-decoration: underline;
}

/* Action Buttons */
.profile-page .btn-outline-secondary,
.profile-page .btn-outline-danger {
  font-weight: 500;
  font-size: 0.9rem;
}

.profile-page .btn-outline-secondary {
  color: #6c63ff;
  border-color: #6c63ff;
}

.profile-page .btn-outline-secondary:hover {
  background-color: #6c63ff;
  color: #fff;
}

.profile-page .btn-outline-danger {
  color: #d9534f;
  border-color: #d9534f;
}

.profile-page .btn-outline-danger:hover {
  background-color: #d9534f;
  color: #fff;
}

.wallet-info .btn-primary {
  background-color: #6c63ff;
  border-color: #6c63ff;
  font-weight: 500;
}

.wallet-info .btn-primary:hover {
  background-color: #5a54c4;
  border-color: #5a54c4;
}

.wallet-info .btn-outline-secondary {
  font-weight: 500;
  color: #6c757d;
}

.wallet-info .btn-outline-secondary:hover {
  background-color: #6c757d;
  color: #fff;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .profile-page {
    max-width: 95%;
    padding: 1.5rem;
  }
  
  .profile-page .profile-picture img {
    width: 90px;
    height: 90px;
  }

  .profile-page h4 {
    font-size: 1.25rem;
  }

  .profile-page p {
    font-size: 0.85rem;
  }
}