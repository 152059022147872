
.container {
  width: 50%;
  margin-top: 40px;
  position: relative;
  border: 1px solid #eceef0;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
}

.import-wallet-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.import-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin-top: 30px;
}

.import-buttons button {
  background-color: #5c3fd1;
  color: white;
  border: none;
  padding: 20px 40px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  flex: 1;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.import-buttons button:hover {
  background-color: #8572d3;
}

.import-buttons button.secondary {
  background-color: #f8f9fc;
  color: #5c3fd1;
  border: 1px solid #5c3fd1;
}

.import-buttons button.secondary:hover {
  background-color: #5c3fd1;
  color: white;
}

.modal-body {
  padding: 30px;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.mnemonic-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.mnemonic-grid input {
  padding: 10px;
  border: 1px solid #dcdce0;
  border-radius: 5px;
}

.wallet-address {
  margin-top: 20px;
  padding: 15px;
  background-color: #f0f2f5;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  text-align: center;
}

.wallet-address h5 {
  margin: 0;
  font-size: 16px;
  color: #5c3fd1;
}

.wallet-address p {
  margin-top: 10px;
  font-weight: bold;
  color: #2d2d2d;
  word-break: break-all;
}

input.form-control {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #dcdce0;
  margin-top: 10px;
}

button.submit-button {
  background-color: #5c3fd1;
  color: white;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

button.submit-button:hover {
  background-color: #8572d3;
}

button.word-button {
  background-color: #f0f2f5;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
}

button.word-button.selected {
  background-color: #5c3fd1;
  color: white;
}

@media (max-width: 768px) {
  .import-buttons {
    flex-direction: column;
  }

  .import-buttons button {
    width: 100%;
    margin: 10px 0;
  }

  .container {
    width: 90%;
  }

  .mnemonic-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .wallet-address {
    width: 95%;
  }
}

